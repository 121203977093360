// src/App.js

import React, {useState} from 'react';
import Users from './components/users/users';
import User from './components/users/user';

import {makeStyles} from '@material-ui/core/styles';
import {BrowserRouter, Route, Switch, Link} from 'react-router-dom';
import SimpleBottomNavigation from "./components/bottomNav";
import System from "./components/system/system";
import Dashboard from "./components/dashboard/dashboard";
import Login from "./components/login/login";
import Report from "./components/report/report";
import AdvancedSearch from "./components/search/advancedSearch";
import PlansMismatch from './components/plansMismatch/plansMismatch';
import Redirect from "react-router-dom/es/Redirect";
import PrivateRoute from './components/PrivateRoute';
import Store from "./states/Store";
import { UserContext } from './states/Store';
import AgentReport from "./components/agentReport/agentReport";
import AgentSubscription from "./components/agentSubscription/agentSubscription";
import Coupons from './components/coupons/coupons.js'
import PhoneVerification from "./components/phoneVerification/PhoneVerification";

const contentStyle = {
    marginBottom: '100px'
};

function App() {

    return (
        <Store>
            <div style={contentStyle}>

                <BrowserRouter>
                    <Route path="/" component={Login}/>
                    <Route path="/phone-verification" component={PhoneVerification} />
                    <Route
                        path="/main"
                        render={({match: {url}}) => (
                            <>
                                <PrivateRoute path={`${url}/dashboard`} component={Dashboard}/>
                                <PrivateRoute path={`${url}/users`} component={Users}/>
                                <PrivateRoute path={`${url}/user`} component={User}/>
                                <PrivateRoute path={`${url}/system`} component={System}/>
                                <PrivateRoute path={`${url}/report`} component={Report}/>
                                <PrivateRoute path={`${url}/search`} component={AdvancedSearch}/>
                                <PrivateRoute path={`${url}/coupons`} component={Coupons}/>
                            </>
                        )}
                    />

                    <Route
                        path="/main/agent"
                        render={({match: {url}}) => (
                            <>
                                <PrivateRoute path={`${url}/report`} component={AgentReport}/>
                                <PrivateRoute path={`${url}/subscription`} component={AgentSubscription}/>
                            </>
                        )}
                    />

                    <SimpleBottomNavigation/>

                </BrowserRouter>
            </div>
        </Store>
    )
}

export default App
