import React, {useState, useEffect, useContext} from 'react';
import Button from "@material-ui/core/Button";
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Redirect from "react-router-dom/es/Redirect";
import Network from "../../network/network";
import {Context} from '../../states/Store'
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import clsx from 'clsx';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';

function Login(props) {

    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        margin: {
            margin: theme.spacing(1),
        },
        withoutLabel: {
            marginTop: theme.spacing(3),
        },
        textField: {
            width: '25ch',
        },
    }));

    const [redirectToReferrer, setRedirectToReferrer] = useState(false);
    const [privileges, setPrivileges] = useState('');
    const [usernameText, setUsernameText] = useState('');
    const [passwordText, setPasswordText] = useState('');
    const [login, setLogin] = useState(false);
    const classes = useStyles();
    const [values, setValues] = React.useState({
        password: '',
        username: '',
        showPassword: false,
    });
    const [loginError, setLoginError] = useState('');

    const [state, dispatch] = useContext(Context);

    const tempUser = localStorage.getItem('tempUser');
    const isPhoneVerificationPath = props.location.pathname === '/phone-verification';

    useEffect(() => {
        let user = localStorage.getItem('user');
        if (!redirectToReferrer && !!user) {
            let parsedUser = JSON.parse(user);
            dispatch({type: 'LOGIN', payload: parsedUser});

            Network.getInstance().setUser(parsedUser);
        }

        if (!isAuthenticated()) {
            clearLocalStorageCacheData();
        }

        hydrateStateWithLocalStorage();
        // add event listener to save state to localStorage
        // when user leaves/refreshes the page
        window.addEventListener(
            "beforeunload",
            saveStateToLocalStorage.bind(this)
        );
        return () => {
            window.removeEventListener(
                "beforeunload",
                saveStateToLocalStorage.bind(this)
            );

            // saves if component has a chance to unmount
            saveStateToLocalStorage();
        }
    }, []);

    function clearLocalStorageCacheData() {
        console.log("clearLocalStorageCacheData");
        localStorage.removeItem('user');
        localStorage.removeItem('redirectToReferrer');
        localStorage.removeItem('privileges');
        localStorage.removeItem('usernameText');
        localStorage.removeItem('passwordText');
    }

    function hasPermission(from) {
        let role = Network.getInstance().getCookies().get('isAuthenticated');
        let isValid = false;
        switch (role) {
            case 'admin':
            case 'viewer':
                isValid = true;
                break;
            case 'contractor':
                isValid = from === '/'
                    || from === '/login'
                    || from.indexOf('main/users') !== -1
                    || from.indexOf('main/user') !== -1
                    || from.indexOf('main/search') !== -1;
                break;
            case 'support':
                isValid = from === '/'
                    || from === '/login'
                    || from.indexOf('main/users') !== -1
                    || from.indexOf('main/user') !== -1
                    || from.indexOf('main/search') !== -1
                    || from.indexOf('main/system') !== -1;
                break;
            case 'agent':
                isValid = from === '/'
                    || from === '/login'
                    || from.indexOf('main/agent') !== -1;
                break;
        }
        if (!isValid) {
            Network.getInstance().removeCookie('isAuthenticated');
            clearLocalStorageCacheData();
        }
        return isValid;
    }

    function callFunc() {
        setRedirectToReferrer(false);
        setLogin(true);
        setLoginError('');

        fetch(`${Network.getInstance().getBaseUrl()}login`, {
            method: "POST",
            body: JSON.stringify({
                userName: values.username,
                password: values.password
            }),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .then((data) => {
                if (data.result === "success") {
                    try {
                        // Store user data and redirect to verification
                        localStorage.setItem('tempUser', JSON.stringify(data));
                        setLogin(false);
                        props.history.push('/phone-verification');
                    } catch (e) {
                        console.log(e);
                        setLogin(false);
                        alert('An error occurred. Please try again.');
                    }
                } else {
                    Network.getInstance().removeAuthentication();
                    setLogin(false);
                    alert(data.error || 'Invalid credentials. Please try again.');
                    localStorage.removeItem('tempUser');
                }
            })
            .catch(function (e) {
                console.log(e);
                Network.getInstance().removeAuthentication()
                setLogin(false);
                alert('An error occurred. Please try again.');
                localStorage.removeItem('tempUser');
            });
    }

    function saveStateToLocalStorage() {
        localStorage.setItem("redirectToReferrer", JSON.stringify({redirectToReferrer}));
        localStorage.setItem("privileges", JSON.stringify({privileges}));
        localStorage.setItem("usernameText", JSON.stringify({usernameText}));
        localStorage.setItem("passwordText", JSON.stringify({passwordText}));
    }


    function hydrateStateWithLocalStorage() {
        let redirectToReferrerValue = localStorage.getItem("redirectToReferrer");
        let privilegesValue = localStorage.getItem("privileges");
        let usernameTextValue = localStorage.getItem("usernameText");
        let passwordTextValue = localStorage.getItem("passwordText");

        // try {
        //     redirectToReferrerValue = JSON.parse(redirectToReferrerValue);
        //     setRedirectToReferrer(redirectToReferrerValue);
        // } catch (e) {
        //     // handle empty string
        //     setRedirectToReferrer(redirectToReferrerValue);
        // }

        try {
            privilegesValue = JSON.parse(privilegesValue);
            setPrivileges(privilegesValue);
        } catch (e) {
            // handle empty string
            setPrivileges(privilegesValue);
        }
        try {
            usernameTextValue = JSON.parse(usernameTextValue);
            setUsernameText(usernameTextValue);
        } catch (e) {
            // handle empty string
            setUsernameText(usernameTextValue);
        }
        try {
            passwordTextValue = JSON.parse(passwordTextValue);
            setPasswordText(passwordTextValue);
        } catch (e) {
            // handle empty string
            setPasswordText(passwordTextValue);
        }
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const from = () => {
        if (isAdmin()) {
            return (props.location.pathname === "/" || (props.location.pathname === "/login" && isAuthenticated())) ? '/main/dashboard' : props.location.pathname;
        } else if (isViewer()) {
            return (props.location.pathname === "/" || (props.location.pathname === "/login" && isAuthenticated())) ? '/main/dashboard' : props.location.pathname;
        } else if (isContractor() || isSupport()) {
            return (props.location.pathname === "/" || (props.location.pathname === "/login" && isAuthenticated())) ? '/main/users' : props.location.pathname;
        } else {
            return (props.location.pathname === "/" || (props.location.pathname === "/login" && isAuthenticated())) ? '/main/agent/report' : props.location.pathname;
        }
    };

    function isAdmin() {
        return Network.getInstance().getCookies().get('isAuthenticated') === 'admin';
    }

    function isViewer() {
        return Network.getInstance().getCookies().get('isAuthenticated') === 'viewer';
    }

    function isContractor() {
        return Network.getInstance().getCookies().get('isAuthenticated') === 'contractor';
    }

    function isSupport() {
        return Network.getInstance().getCookies().get('isAuthenticated') === 'support';
    }

    function isAuthenticated() {
        return !!Network.getInstance().getCookies().get('isAuthenticated');
    }

    const handleClickShowPassword = () => {
        setValues({...values, showPassword: !values.showPassword});
    };

    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };

    return (
        <div>
            {isPhoneVerificationPath ? null : (
            (hasPermission(props.location.pathname) && isAuthenticated()) ?
                <Redirect to={from()}/>

                :

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Col>
                            <Row style={{ display: 'flex', alignItems: 'center', marginLeft: '1vw' }}>
                                <p>You must logged in to view this page</p>
                            </Row>
                            <Col style={{ alignItems: 'center', marginLeft: '1vw' }}>
                                <form noValidate autoComplete="off" style={{ display: 'flex', alignItems: 'center' }}>

                                <TextField id="standard-basic" label="Name" variant="standard"
                                           onChange={handleChange('username')}
                                           value={values.username}
                                />
                                <FormControl style={{marginLeft: '2vw'}}
                                             className={clsx(classes.margin, classes.textField)}>
                                    <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                                    <Input
                                        id="standard-adornment-password"
                                        type={values.showPassword ? 'text' : 'password'}
                                        value={values.password}
                                        onChange={handleChange('password')}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {values.showPassword ? <Visibility/> : <VisibilityOff/>}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </form>
                            <Container
                                style={{
                                    marginTop: '1vw',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                {
                                    login
                                        ?
                                        <div style={{width: '20%', textAlign: 'center'}}>
                                            <CircularProgress/>
                                        </div>
                                        :
                                        <Button onClick={() => callFunc()} variant="contained" color="primary">
                                            Login
                                        </Button>
                                }
                            </Container>
                        </Col>
                    </Col>
                </div>
                )}
        </div>
    );
}


export default Login
